'use client'

import { Header } from '@components/Header'
import { ErrorContainer } from '@containers/Error/Error'

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
  return (
    <>
      <Header />
      <ErrorContainer error={error} reset={reset} />
    </>
  )
}
